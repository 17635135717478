import "./App.css";
import { useLocation, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Outlet, Navigate } from "react-router";
import PrivateRoute from "./PrivateRoute";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import { dm, dm2 } from "./theme/enc";
import { useEffect, useState } from "react";
import { hs } from "./theme/host";
import XD45s from "./pages/game/XDia/XD45s";
import XD60s from "./pages/game/XDia/XD60s";
import TX45s from "./pages/game/TXiu/TX45s";
import TX60s from "./pages/game/TXiu/TX60s";
import Greedy from "./pages/game/Greedy/Greedy";
import CatDog from "./pages/game/CatDog/CatDog";
import ListXS from "./pages/game/Xoso";
//XS120s
import XS120sDedb from "./pages/game/XS120s/Dedb";
import XS120sDedaudb from "./pages/game/XS120s/Dedaudb";
import XS120sDe1 from "./pages/game/XS120s/De1";
import XS120sDedau1 from "./pages/game/XS120s/Dedau1";
import XS120sDegiai7 from "./pages/game/XS120s/Degiai7";
import XS120sLo2 from "./pages/game/XS120s/Lo2";
import XS120sLo2so1k from "./pages/game/XS120s/Lo2so1k";
import XS120sLo2dau from "./pages/game/XS120s/Lo2dau";
import XS120sLo3 from "./pages/game/XS120s/Lo3";
import XS120sLo4 from "./pages/game/XS120s/Lo4";
import XS120sLoxien2 from "./pages/game/XS120s/Loxien2";
import XS120sLoxien3 from "./pages/game/XS120s/Loxien3";
import XS120sLoxien4 from "./pages/game/XS120s/Loxien4";
import XS120sTruotxien4 from "./pages/game/XS120s/Truotxien4";
import XS120sTruotxien8 from "./pages/game/XS120s/Truotxien8";
import XS120sTruotxien10 from "./pages/game/XS120s/Truotxien10";
import XS120s3cangdb from "./pages/game/XS120s/3cangdb";
import XS120s3cang1 from "./pages/game/XS120s/3cang1";
import XS120s3cangdau from "./pages/game/XS120s/3cangdau";
import XS120s3cangdauduoi from "./pages/game/XS120s/3cangdauduoi";
import XS120s4cangdb from "./pages/game/XS120s/4cangdb";
import XS120sdau from "./pages/game/XS120s/Dau";
import XS120sduoi from "./pages/game/XS120s/Duoi";
//XS75s
import XS75sDedb from "./pages/game/XS75s/Dedb";
import XS75sDedaudb from "./pages/game/XS75s/Dedaudb";
import XS75sDe1 from "./pages/game/XS75s/De1";
import XS75sDedau1 from "./pages/game/XS75s/Dedau1";
import XS75sDegiai7 from "./pages/game/XS75s/Degiai7";
import XS75sLo2 from "./pages/game/XS75s/Lo2";
import XS75sLo2so1k from "./pages/game/XS75s/Lo2so1k";
import XS75sLo2dau from "./pages/game/XS75s/Lo2dau";
import XS75sLo3 from "./pages/game/XS75s/Lo3";
import XS75sLo4 from "./pages/game/XS75s/Lo4";
import XS75sLoxien2 from "./pages/game/XS75s/Loxien2";
import XS75sLoxien3 from "./pages/game/XS75s/Loxien3";
import XS75sLoxien4 from "./pages/game/XS75s/Loxien4";
import XS75sTruotxien4 from "./pages/game/XS75s/Truotxien4";
import XS75sTruotxien8 from "./pages/game/XS75s/Truotxien8";
import XS75sTruotxien10 from "./pages/game/XS75s/Truotxien10";
import XS75s3cangdb from "./pages/game/XS75s/3cangdb";
import XS75s3cang1 from "./pages/game/XS75s/3cang1";
import XS75s3cangdau from "./pages/game/XS75s/3cangdau";
import XS75s3cangdauduoi from "./pages/game/XS75s/3cangdauduoi";
import XS75s4cangdb from "./pages/game/XS75s/4cangdb";
import XS75sdau from "./pages/game/XS75s/Dau";
import XS75sduoi from "./pages/game/XS75s/Duoi";
//XSMB
import MBDedb from "./pages/game/XSMB/Dedb";
import MBDedaudb from "./pages/game/XSMB/Dedaudb";
import MBDe1 from "./pages/game/XSMB/De1";
import MBDedau1 from "./pages/game/XSMB/Dedau1";
import MBDegiai7 from "./pages/game/XSMB/Degiai7";
import MBLo2 from "./pages/game/XSMB/Lo2";
import MBLo2so1k from "./pages/game/XSMB/Lo2so1k";
import MBLo2dau from "./pages/game/XSMB/Lo2dau";
import MBLo3 from "./pages/game/XSMB/Lo3";
import MBLo4 from "./pages/game/XSMB/Lo4";
import MBLoxien2 from "./pages/game/XSMB/Loxien2";
import MBLoxien3 from "./pages/game/XSMB/Loxien3";
import MBLoxien4 from "./pages/game/XSMB/Loxien4";
import MBTruotxien4 from "./pages/game/XSMB/Truotxien4";
import MBTruotxien8 from "./pages/game/XSMB/Truotxien8";
import MBTruotxien10 from "./pages/game/XSMB/Truotxien10";
import MB3cangdb from "./pages/game/XSMB/3cangdb";
import MB3cang1 from "./pages/game/XSMB/3cang1";
import MB3cangdau from "./pages/game/XSMB/3cangdau";
import MB3cangdauduoi from "./pages/game/XSMB/3cangdauduoi";
import MB4cangdb from "./pages/game/XSMB/4cangdb";
import MBdau from "./pages/game/XSMB/Dau";
import MBduoi from "./pages/game/XSMB/Duoi";
//XSMT
import MTDedb from "./pages/game/XSMT/Dedb";
import MTDedaudb from "./pages/game/XSMT/Dedaudb";
import MTDe1 from "./pages/game/XSMT/De1";
import MTDedau1 from "./pages/game/XSMT/Dedau1";
import MTDegiai7 from "./pages/game/XSMT/Degiai7";
import MTDedauduoi from "./pages/game/XSMT/Dedauduoi";
import MTLo2 from "./pages/game/XSMT/Lo2";
import MTLo2so1k from "./pages/game/XSMT/Lo2so1k";
import MTLo2dau from "./pages/game/XSMT/Lo2dau";
import MTLo3 from "./pages/game/XSMT/Lo3";
import MTLo4 from "./pages/game/XSMT/Lo4";
import MTLoxien2 from "./pages/game/XSMT/Loxien2";
import MTLoxien3 from "./pages/game/XSMT/Loxien3";
import MTLoxien4 from "./pages/game/XSMT/Loxien4";
import MTTruotxien4 from "./pages/game/XSMT/Truotxien4";
import MTTruotxien8 from "./pages/game/XSMT/Truotxien8";
import MTTruotxien10 from "./pages/game/XSMT/Truotxien10";
import MT3cangdb from "./pages/game/XSMT/3cangdb";
import MT3cang1 from "./pages/game/XSMT/3cang1";
import MT3cangdau from "./pages/game/XSMT/3cangdau";
import MT3cangdauduoi from "./pages/game/XSMT/3cangdauduoi";
import MT4cangdb from "./pages/game/XSMT/4cangdb";
import MTdau from "./pages/game/XSMT/Dau";
import MTduoi from "./pages/game/XSMT/Duoi";
//XSMN
import MNDedb from "./pages/game/XSMN/Dedb";
import MNDedaudb from "./pages/game/XSMN/Dedaudb";
import MNDe1 from "./pages/game/XSMN/De1";
import MNDedau1 from "./pages/game/XSMN/Dedau1";
import MNDegiai7 from "./pages/game/XSMN/Degiai7";
import MNDedauduoi from "./pages/game/XSMN/Dedauduoi";
import MNLo2 from "./pages/game/XSMN/Lo2";
import MNLo2so1k from "./pages/game/XSMN/Lo2so1k";
import MNLo2dau from "./pages/game/XSMN/Lo2dau";
import MNLo3 from "./pages/game/XSMN/Lo3";
import MNLo4 from "./pages/game/XSMN/Lo4";
import MNLoxien2 from "./pages/game/XSMN/Loxien2";
import MNLoxien3 from "./pages/game/XSMN/Loxien3";
import MNLoxien4 from "./pages/game/XSMN/Loxien4";
import MNTruotxien4 from "./pages/game/XSMN/Truotxien4";
import MNTruotxien8 from "./pages/game/XSMN/Truotxien8";
import MNTruotxien10 from "./pages/game/XSMN/Truotxien10";
import MN3cangdb from "./pages/game/XSMN/3cangdb";
import MN3cang1 from "./pages/game/XSMN/3cang1";
import MN3cangdau from "./pages/game/XSMN/3cangdau";
import MN3cangdauduoi from "./pages/game/XSMN/3cangdauduoi";
import MN4cangdb from "./pages/game/XSMN/4cangdb";
import MNdau from "./pages/game/XSMN/Dau";
import MNduoi from "./pages/game/XSMN/Duoi";
// User
import Login from "./pages/user/Login";
import Register from "./pages/user/Register";
import Home from "./pages/user/Home";
import Service from "./pages/user/Service";
import Notification from "./pages/user/Notification";
import Profile from "./pages/user/Profile";
import HistoryAll from "./pages/user/HistoryAll";
import HistoryBet from "./pages/user/HistoryBet";
import HistoryGame from "./pages/user/HistoryCasino";
import HistoryRecharge from "./pages/user/HistoryRecharge";
import HistoryWithDraw from "./pages/user/HistoryWithDraw";
import HistoryPromo from "./pages/user/HistoryPromo";
import RechargeMenu from "./pages/user/RechargeMenu";
import Recharge from "./pages/user/Recharge";
import WithDraw from "./pages/user/WithDraw";
import AddBank from "./pages/user/AddBank";
import EditBank from "./pages/user/EditBank";
import ResetPassword from "./pages/user/ResetPassword";
import Pass2 from "./pages/user/Pass2";
import TransferMoney from "./pages/user/TransferMoney";
import TransferGame from "./pages/user/TransferGame";
import RoomChat from "./pages/user/RoomChat";
import Invite from "./pages/user/Invite";
import MoneySave from "./pages/user/MoneySave";
import DetailProfile from "./pages/user/DetailProfile";
import TopGame from "./pages/user/TopGame";
// Admin
import PrivateCustomer from "./PrivateCustomer";
import DashboardCustomer from "./pages/admin/DashboardCustomer";
import UsersByEmployeeNV from "./pages/admin/UsersByEmployeeNV";
import HistoryBetAll from "./pages/admin/HistoryBetAll";
import BankAdmin from "./pages/admin/BankAdmin";
import Dashboard from "./pages/admin/Dashboard";
import Request from "./pages/admin/Request";
import Users from "./pages/admin/Users";
import Add from "./pages/admin/Add";
import UserProfile from "./pages/admin/UserProfile";
import ThongBao from "./pages/admin/ThongBao";
import Setting from "./pages/admin/Setting";
import Config from "./pages/admin/Config";
import SettingNotify from "./pages/admin/SettingNotify";
import Employee from "./pages/admin/Employee";
import AllEmployee from "./pages/admin/AllEmployee";
import UsersByEmployee from "./pages/admin/UsersByEmployee";
import SettingProfit from "./pages/admin/SettingProfit";
import HoaHong from "./pages/admin/HoaHong";
import HistoryCasino from "./pages/admin/HistoryCasino";

function App() {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const refParam = urlParams.get("ref");
        if (refParam) {
            localStorage.setItem("affiliate", refParam);
        }
    }, []);
    const location = useLocation();
    const encodedDo = dm() + "zRPSFp1" + dm2();
    const currentDo = window;
    const encodedCurrentDo = btoa(btoa(currentDo.location[hs() + "name"])+'v');
    const isAllowedDo = encodedCurrentDo === encodedDo;
    return (
        <div className="App">
            {isAllowedDo ? (
                <Routes key={location.pathname} location={location}>
                    <Route element={<Login />} path="/login" />
                    <Route element={<Register />} path="/register" />
                    <Route element={<Home />} path="/" />
                    <Route element={<Service />} path="/service" />
                    <Route path="/" element={<PrivateRoute />}>
                        <Route element={<ListXS />} path="/xoso" />
                        <Route element={<XD45s />} path="/xd45s" />
                        <Route element={<XD60s />} path="/xd60s" />
                        <Route element={<TX45s />} path="/tx45s" />
                        <Route element={<TX60s />} path="/tx60s" />
                        <Route element={<Greedy />} path="/greedy" />
                        <Route element={<CatDog />} path="/catdog" />

                        <Route element={<XS120sDedb />} path="/xs120s/dedb" />
                        <Route element={<XS120sDedaudb />} path="/xs120s/dedaudb" />
                        <Route element={<XS120sDe1 />} path="/xs120s/de1" />
                        <Route element={<XS120sDedau1 />} path="/xs120s/dedau1" />
                        <Route element={<XS120sDegiai7 />} path="/xs120s/degiai7" />
                        <Route element={<XS120sLo2 />} path="/xs120s/lo2" />
                        <Route element={<XS120sLo2so1k />} path="/xs120s/lo2so1k" />
                        <Route element={<XS120sLo2dau />} path="/xs120s/lo2dau" />
                        <Route element={<XS120sLo3 />} path="/xs120s/lo3" />
                        <Route element={<XS120sLo4 />} path="/xs120s/lo4" />
                        <Route element={<XS120sLoxien2 />} path="/xs120s/loxien2" />
                        <Route element={<XS120sLoxien3 />} path="/xs120s/loxien3" />
                        <Route element={<XS120sLoxien4 />} path="/xs120s/loxien4" />
                        <Route element={<XS120s3cangdb />} path="/xs120s/3cangdb" />
                        <Route element={<XS120s3cang1 />} path="/xs120s/3cang1" />
                        <Route element={<XS120s3cangdau />} path="/xs120s/3cangdau" />
                        <Route element={<XS120s3cangdauduoi />} path="/xs120s/3cangdauduoi" />
                        <Route element={<XS120sTruotxien4 />} path="/xs120s/truotxien4" />
                        <Route element={<XS120sTruotxien8 />} path="/xs120s/truotxien8" />
                        <Route element={<XS120sTruotxien10 />} path="/xs120s/truotxien10" />
                        <Route element={<XS120s4cangdb />} path="/xs120s/4cangdb" />
                        <Route element={<XS120sdau />} path="/xs120s/dau" />
                        <Route element={<XS120sduoi />} path="/xs120s/duoi" />

                        <Route element={<XS75sDedb />} path="/xs75s/dedb" />
                        <Route element={<XS75sDedaudb />} path="/xs75s/dedaudb" />
                        <Route element={<XS75sDe1 />} path="/xs75s/de1" />
                        <Route element={<XS75sDedau1 />} path="/xs75s/dedau1" />
                        <Route element={<XS75sDegiai7 />} path="/xs75s/degiai7" />
                        <Route element={<XS75sLo2 />} path="/xs75s/lo2" />
                        <Route element={<XS75sLo2so1k />} path="/xs75s/lo2so1k" />
                        <Route element={<XS75sLo2dau />} path="/xs75s/lo2dau" />
                        <Route element={<XS75sLo3 />} path="/xs75s/lo3" />
                        <Route element={<XS75sLo4 />} path="/xs75s/lo4" />
                        <Route element={<XS75sLoxien2 />} path="/xs75s/loxien2" />
                        <Route element={<XS75sLoxien3 />} path="/xs75s/loxien3" />
                        <Route element={<XS75sLoxien4 />} path="/xs75s/loxien4" />
                        <Route element={<XS75s3cangdb />} path="/xs75s/3cangdb" />
                        <Route element={<XS75s3cang1 />} path="/xs75s/3cang1" />
                        <Route element={<XS75s3cangdau />} path="/xs75s/3cangdau" />
                        <Route element={<XS75s3cangdauduoi />} path="/xs75s/3cangdauduoi" />
                        <Route element={<XS75sTruotxien4 />} path="/xs75s/truotxien4" />
                        <Route element={<XS75sTruotxien8 />} path="/xs75s/truotxien8" />
                        <Route element={<XS75sTruotxien10 />} path="/xs75s/truotxien10" />
                        <Route element={<XS75s4cangdb />} path="/xs75s/4cangdb" />
                        <Route element={<XS75sdau />} path="/xs75s/dau" />
                        <Route element={<XS75sduoi />} path="/xs75s/duoi" />
                        {/* Xổ số miền bắc */}
                        <Route element={<MBDedb />} path="/xsmb/dedb" />
                        <Route element={<MBDedaudb />} path="/xsmb/dedaudb" />
                        <Route element={<MBDe1 />} path="/xsmb/de1" />
                        <Route element={<MBDedau1 />} path="/xsmb/dedau1" />
                        <Route element={<MBDegiai7 />} path="/xsmb/degiai7" />
                        <Route element={<MBLo2 />} path="/xsmb/lo2" />
                        <Route element={<MBLo2so1k />} path="/xsmb/lo2so1k" />
                        <Route element={<MBLo2dau />} path="/xsmb/lo2dau" />
                        <Route element={<MBLo3 />} path="/xsmb/lo3" />
                        <Route element={<MBLo4 />} path="/xsmb/lo4" />
                        <Route element={<MBLoxien2 />} path="/xsmb/loxien2" />
                        <Route element={<MBLoxien3 />} path="/xsmb/loxien3" />
                        <Route element={<MBLoxien4 />} path="/xsmb/loxien4" />
                        <Route element={<MB3cangdb />} path="/xsmb/3cangdb" />
                        <Route element={<MB3cang1 />} path="/xsmb/3cang1" />
                        <Route element={<MB3cangdau />} path="/xsmb/3cangdau" />
                        <Route element={<MB3cangdauduoi />} path="/xsmb/3cangdauduoi" />
                        <Route element={<MBTruotxien4 />} path="/xsmb/truotxien4" />
                        <Route element={<MBTruotxien8 />} path="/xsmb/truotxien8" />
                        <Route element={<MBTruotxien10 />} path="/xsmb/truotxien10" />
                        <Route element={<MB4cangdb />} path="/xsmb/4cangdb" />
                        <Route element={<MBdau />} path="/xsmb/dau" />
                        <Route element={<MBduoi />} path="/xsmb/duoi" />
                        {/* Xổ số miền nam */}
                        <Route element={<MNDedb />} path="/xsmn/dedb/:id" />
                        <Route element={<MNDedaudb />} path="/xsmn/dedaudb/:id" />
                        <Route element={<MNDe1 />} path="/xsmn/de1/:id" />
                        <Route element={<MNDedau1 />} path="/xsmn/dedau1/:id" />
                        <Route element={<MNDegiai7 />} path="/xsmn/degiai7/:id" />
                        <Route element={<MNDedauduoi />} path="/xsmn/dedauduoi/:id" />
                        <Route element={<MNLo2 />} path="/xsmn/lo2/:id" />
                        <Route element={<MNLo2so1k />} path="/xsmn/lo2so1k/:id" />
                        <Route element={<MNLo2dau />} path="/xsmn/lo2dau/:id" />
                        <Route element={<MNLo3 />} path="/xsmn/lo3/:id" />
                        <Route element={<MNLo4 />} path="/xsmn/lo4/:id" />
                        <Route element={<MNLoxien2 />} path="/xsmn/loxien2/:id" />
                        <Route element={<MNLoxien3 />} path="/xsmn/loxien3/:id" />
                        <Route element={<MNLoxien4 />} path="/xsmn/loxien4/:id" />
                        <Route element={<MN3cangdb />} path="/xsmn/3cangdb/:id" />
                        <Route element={<MN3cang1 />} path="/xsmn/3cang1/:id" />
                        <Route element={<MN3cangdau />} path="/xsmn/3cangdau/:id" />
                        <Route element={<MN3cangdauduoi />} path="/xsmn/3cangdauduoi/:id" />
                        <Route element={<MNTruotxien4 />} path="/xsmn/truotxien4/:id" />
                        <Route element={<MNTruotxien8 />} path="/xsmn/truotxien8/:id" />
                        <Route element={<MNTruotxien10 />} path="/xsmn/truotxien10/:id" />
                        <Route element={<MN4cangdb />} path="/xsmn/4cangdb/:id" />
                        <Route element={<MNdau />} path="/xsmn/dau/:id" />
                        <Route element={<MNduoi />} path="/xsmn/duoi/:id" />
                        {/* Xổ số miền trung */}
                        <Route element={<MTDedb />} path="/xsmt/dedb/:id" />
                        <Route element={<MTDedaudb />} path="/xsmt/dedaudb/:id" />
                        <Route element={<MTDe1 />} path="/xsmt/de1/:id" />
                        <Route element={<MTDedau1 />} path="/xsmt/dedau1/:id" />
                        <Route element={<MTDegiai7 />} path="/xsmt/degiai7/:id" />
                        <Route element={<MTDedauduoi />} path="/xsmt/dedauduoi/:id" />
                        <Route element={<MTLo2 />} path="/xsmt/lo2/:id" />
                        <Route element={<MTLo2so1k />} path="/xsmt/lo2so1k/:id" />
                        <Route element={<MTLo2dau />} path="/xsmt/lo2dau/:id" />
                        <Route element={<MTLo3 />} path="/xsmt/lo3/:id" />
                        <Route element={<MTLo4 />} path="/xsmt/lo4/:id" />
                        <Route element={<MTLoxien2 />} path="/xsmt/loxien2/:id" />
                        <Route element={<MTLoxien3 />} path="/xsmt/loxien3/:id" />
                        <Route element={<MTLoxien4 />} path="/xsmt/loxien4/:id" />
                        <Route element={<MT3cangdb />} path="/xsmt/3cangdb/:id" />
                        <Route element={<MT3cang1 />} path="/xsmt/3cang1/:id" />
                        <Route element={<MT3cangdau />} path="/xsmt/3cangdau/:id" />
                        <Route element={<MT3cangdauduoi />} path="/xsmt/3cangdauduoi/:id" />
                        <Route element={<MTTruotxien4 />} path="/xsmt/truotxien4/:id" />
                        <Route element={<MTTruotxien8 />} path="/xsmt/truotxien8/:id" />
                        <Route element={<MTTruotxien10 />} path="/xsmt/truotxien10/:id" />
                        <Route element={<MT4cangdb />} path="/xsmt/4cangdb/:id" />
                        <Route element={<MTdau />} path="/xsmt/dau/:id" />
                        <Route element={<MTduoi />} path="/xsmt/duoi/:id" />
                        {/* User */}
                        <Route element={<Profile />} path="/profile" />
                        <Route element={<MoneySave />} path="/money" />
                        <Route element={<HistoryAll />} path="/history" />
                        <Route element={<HistoryBet />} path="/historyplay" />
                        <Route element={<HistoryGame />} path="/historycasino" />
                        <Route element={<HistoryRecharge />} path="/recharge_record" />
                        <Route element={<HistoryWithDraw />} path="/withdraw_record" />
                        <Route element={<RechargeMenu />} path="/recharge" />
                        <Route element={<Recharge />} path="/recharge/:id" />
                        <Route element={<WithDraw />} path="/withdraw" />
                        <Route element={<AddBank />} path="/addbank" />
                        <Route element={<EditBank />} path="/bank/:id" />
                        <Route element={<ResetPassword />} path="/password" />
                        <Route element={<Pass2 />} path="/pass2" />
                        <Route element={<TransferMoney />} path="/transfer" />
                        <Route element={<TransferGame />} path="/transfergame" />
                        <Route element={<RoomChat />} path="/chat" />
                        <Route element={<Invite />} path="/invite" />
                        <Route element={<Notification />} path="/notification" />
                        <Route element={<DetailProfile />} path="/detail" />
                        <Route element={<TopGame />} path="/topgame" />
                        <Route element={<HistoryPromo />} path="/record_promo" />
                    </Route>

                    {/*<Route path="/admin" element={<PrivateRouteAdmin />}>
                        <Route element={<Dashboard />} path="/admin" exact />
                        <Route element={<HistoryCasino />} path="/admin/historyapi" exact />
                        <Route element={<HistoryBetAll />} path="/admin/history" exact />
                        <Route element={<Request />} path="/admin/request" exact />
                        <Route element={<Add />} path="/admin/add" exact />
                        <Route element={<Users />} path="/admin/users" exact />
                        <Route element={<UserProfile />} path="/admin/user/:id" />
                        <Route element={<AllEmployee />} path="/admin/allNV" exact />
                        <Route element={<Employee />} path="/admin/employee" exact />
                        <Route element={<UsersByEmployee />} path="/admin/employee/:id" />
                        <Route element={<Setting />} path="/admin/setting" exact />
                        <Route element={<Config />} path="/admin/config" exact />
                        <Route element={<SettingProfit />} path="/admin/setting/profit" exact />
                        <Route element={<HoaHong />} path="/admin/setting/money" exact />
                        <Route element={<SettingNotify />} path="/admin/settingnotify" exact />
                        <Route element={<BankAdmin />} path="/admin/bank" exact />
                        <Route element={<ThongBao />} path="/admin/notification" />
                    </Route>
                    <Route path="/customer" element={<PrivateCustomer />}>
                        <Route element={<DashboardCustomer />} path="/customer" exact />
                        <Route element={<UsersByEmployeeNV />} path="/customer/usercustomer" exact />
                    </Route>*/}
                    <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
            ) : null}
        </div>
    );
}

export default App;
